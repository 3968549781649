import { useState } from "react";
import {
  Form,
  useSubmit,
  useFormAction,
  redirect,
  useActionData,
  useNavigation,
} from "react-router-dom";
//styling import
import styled from "styled-components";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CodeSample from "../SharedStyles/CodeSample";

import {
  LabComponent_div,
  LabComponents_div,
  LabDecription_div,
  LabAction_div,
  Actions_div,
} from "../SharedStyles/LabStyles";
import javascript from "highlight.js/lib/languages/javascript";

const SD_div = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding: 20px 10px;
  border-radius: 12px;

  h1 {
    color: #2d3250;
    margin: auto;
    text-align: left;
  }

  p {
    color: #2d3250;
  }
`;

const APIs_div = styled.div`
  width: 100%;
  background-color: none;
  display: block;
  justify-content: left;
  gap: 1rem;
`;

const Response_div = styled.div`
  border: 1px solid azure;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: #cccccc;
  width: 100%;

  display: block;
  justify-content: left;
  gap: 1rem;
  h3 {
    color: #000;
    margin: 0%;
    padding: 0;
  }
  p {
    margin: 0;
    padding: 0;
  }
`;

const Text_div = styled.div`
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  width: 100%;
  display: block;
  justify-content: left;
  gap: 1rem;
  p {
    color: #2d3250;
  }
`;

const CODE = `class LanguageDetect:
    def __init__(self):
        
        language_key = os.getenv('LANGUAGE_KEY')
        language_endpoint = os.getenv('LANGUAGE_ENDPOINT')
        if not language_key or not language_endpoint:
            raise ValueError("Missing required environment variables: 'LANGUAGE_KEY' or 'LANGUAGE_ENDPOINT'.")
       
        self.ta_client = TextAnalyticsClient(
            endpoint=language_endpoint,
            credential=AzureKeyCredential(language_key)
        )
    def detect_language_text(self, text: str):
        
        if not text or not isinstance(text, str):        
            return {"error": "Invalid input: Text must be a non-empty string."}, 400
        if len(text) > 5000:  
            return {"error": "Input text exceeds the maximum allowed length of 5000 characters."}, 400
        try:
           
            documents_list = [text]
            detect_language = self.ta_client.detect_language(documents=documents_list)[0]
            
            json_response = {
                "input_text": text,
                "detected_language": detect_language.primary_language.name,
                "detected_language_code": detect_language.primary_language.iso6391_name,
                "detected_language_confidence": detect_language.primary_language.confidence_score,
            }
            return json_response
        except Exception as err:
           
            return {"error": "An unexpected error occurred while detecting the language."}, 500`;

export default function DetectLanguage() {
  const responseData = useActionData();
  const status = useNavigation().state;
  console.log(status);
  const [textValue, setTextValue] = useState("");
  const [showCode, setShowCode] = useState(false);
  const showCodeHandler = () => {
    setShowCode(!showCode);
  };

  return (
    <>
      <LabDecription_div>
        <h1>Lab: Detect Language of text</h1>
        <h2>Service: Azure AI Languange</h2>
        <p>
          This lab uses preconfigured feature that can detect the language a
          document is written in, and returns a language code for a wide range
          of languages, variants, dialects, and some regional/cultural
          languages.
        </p>

        {/* <h2>Components</h2> */}
        {/* <LabComponents_div>
          <LabComponent_div>
            <h3>User Interface</h3>
            <p>Static Web Site (this page) coded with ReactJS</p>
          </LabComponent_div>
          <LabComponent_div>
            <h3>Backend</h3>
            <p>API REST coded with Pyton Flask</p>
          </LabComponent_div>
          <LabComponent_div>
            <h3>Azure AI </h3>
            <p>Language Detection Module</p>
          </LabComponent_div>

        </LabComponents_div> */}
        <Actions_div>
          <Button variant="outlined" onClick={showCodeHandler}>
            Try it out!
          </Button>
          <Button variant="outlined" onClick={showCodeHandler}>
            View Code
          </Button>
        </Actions_div>
        <LabAction_div>
          {!showCode && (
            <Form method="post">
              <p> Type some text or copy/paste a text to detect language.</p>
              <TextField
                id="text"
                name="text"
                value={textValue}
                onChange={(event) => {
                  setTextValue(event.target.value);
                }}
                label="Type some text..."
                placeholder="Placeholder"
                multiline
                rows={4}
                fullWidth
              />
              <Actions_div>
                <Button type="Submit" variant="outlined">
                  Detect
                </Button>
              </Actions_div>
            </Form>
          )}

          {status === "submitting" && <p>Submitting...</p>}
          {responseData && status == "idle" && (
            <Response_div>
              <h2>Response</h2>
              <h3>{responseData.detected_language}</h3>

              <p>ISO CODE: {responseData.detected_language_code}</p>
              <p>
                Confidence: {responseData.detected_language_confidence * 100} %{" "}
              </p>
              <h3>Input Text:</h3>
              <Text_div>
                <p>{responseData.input_text}</p>
              </Text_div>
            </Response_div>
          )}
          {showCode && (
            <div>
              <p>
                The Text analytics client recieves a document and process it on the Language Endpoint. This will return the results with the
                detected language and the confidence score.
              </p>
              <CodeSample language={"python"} code={CODE} />
            </div>
          )}
        </LabAction_div>
      </LabDecription_div>
    </>
  );
}

export async function action({ request }) {
  const data = await request.formData();
  const apiData = {
    input_text: data.get("text"),
  };
  console.log(apiData);
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_SERVER}/detect-language`,
    {
      method: "post",
      body: JSON.stringify(apiData),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
}
