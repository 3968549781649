import styled from "styled-components";
import { useState } from "react";

import DetectLanguage from "./DetectLanguage";
const Api_ul = styled.ul`
  padding: 0;
  width: 100%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  list-style-type: none;
  gap: 1rem;
`;

const Api_li = styled.li`
  text-decoration: none;
  background-color: #2d3250;
  color: #fff;
  border: 1px solid #2d3250;
  border-radius: 12px;
  padding: 4px;
  cursor: pointer;
`;

const Service_div = styled.div`
  border: 1px solid #2d3250;
  width: 80%;
  margin: auto;
  margin-top: 30px;
  padding: 10px;
  border-radius: 12px;
  height: fit-content;

  h1 {
    text-align: center;
    font-size: 38px;
  }
`;
export default function APIs({ apiRoutes }) {
  const [seletectedAPI, setSelectedAPI] = useState("");

  const clickHandler = (event) => {
    console.log(event.target.id);
    setSelectedAPI(event.target.id);
  };

  return <DetectLanguage />;
}
