import { useState } from "react";
import { Button } from "@mui/material";
import styled from "styled-components";
import CodeSample from "./CodeSample";

export const LabDecription_div = styled.div`
  border: 1px solid #f5f5ff;
  border-radius: 0.5rem;
  margin: auto;
  margin-top: 20px;
  padding: 1rem;
  width: 90%;
  background-color: #f5f5f5;
  p {
    padding-bottom: 1rem;
    font-size: 14px;
  }
  h1 {
    padding-bottom: 1rem;
  }
  h2 {
    padding-bottom: 1rem;
  }
  h3 {
    padding-bottom: 1rem;
    font-weight: bold;
    color: #141414;
  }
`;

export const LabComponents_div = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 2rem;
  gap: 1rem;
`;
export const LabComponent_div = styled.div`
  padding: 1rem;
  border: 1px solid #f5f5ff;
  border-radius: 0.5rem;
  min-width: 30%;
  max-width: 30%;
`;

export const Actions_div = styled.div`
  display: flex;
  justify-content: Left;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 0.5rem;
  gap: 1rem;
`;

export const LabAction_div = styled.div`
border: 1px solid #adadad73;
border-radius: 0.5rem;
  display: block;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 2rem;
  gap: 1rem;
`;
export function LabComponent({ title, description,  code }) {
  console.log(code)

  const [viewMore, setViewMore] = useState(false)
  const [codeSample, setCodeSample] = useState(false)


  const singleSentence = description.split(".")[0];

  function expandDescription() {
    setViewMore(!viewMore);
  }
  function showCodeSample() {
    setCodeSample(!codeSample);
  }
  return (
    <LabComponent_div>
      <h3>{title}</h3>
      <p> {viewMore && description}</p>
      <p> {!viewMore && singleSentence}.</p>
      <Actions_div>
        <Button onClick={expandDescription} variant="text">View More</Button>
        <Button onClick={showCodeSample} variant="contained">Code Sample</Button>
      </Actions_div>
      <div>
        {codeSample && <CodeSample code={code.sample} language={code.language} />}
      </div>
    </LabComponent_div>
  );
}
