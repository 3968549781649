import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import "highlight.js/styles/atom-one-dark.css";

import { useEffect, useRef } from "react";

import styled from "styled-components";

const CodeSnippetContainer = styled.code`
  background-color: #282c34;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
  overflow-x: auto;
`;

hljs.registerLanguage("javascript", javascript);

const CodeSample = ({code, language}) => {
  const codeRef = useRef(null);

  useEffect(() => {
    hljs.highlightBlock(codeRef.current);
  }, []);

  return (
    <pre>
      <CodeSnippetContainer
        className={language}
        style={{ background: "#373c52" }}
        ref={codeRef}
      >
        {`${code}`}
      </CodeSnippetContainer>
    </pre>
  );
};

export default CodeSample;
