import { useState } from "react";
import { Form, useSubmit, redirect } from "react-router-dom";

import styled from "styled-components";

const FormControl_div = styled.div`
  display: inline-block;
  flex-direction: column;
  gap: 1rem;
`;
export default function SpeechTranslateForm() {
  const [userInput, setUserInput] = useState({
    file: null,
    targetLanguage: null,
    passcode: null,
  });

  const formSubmit = useSubmit();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageLoad = (event) => {
    setUserInput((prevState) => ({
      ...prevState,
      file: event.target.files[0],
    }));
  };

  const formData = new FormData();
  formData.append("file", userInput.file);
  formData.append("target_language", userInput.targetLanguage);
  formData.append("passcode", userInput.passcode);

  const handleFormData = () => {
    // formSubmit(formData, {
    //   method: "POST",
    //   action: ".",
    //   encType: "multipart/form-data",
    // });
  };

  return (
    <div>
      <Form method="POST" action="." encType="multipart/form-data">
        <FormControl_div>
          {" "}
          <label>Select a file</label>
          <input
            name="file"
            type="file"
            accept="audio/wav"
            onChange={handleImageLoad}
          ></input>
        </FormControl_div>
        <FormControl_div>
          <label>Target Languange</label>
          <select name="target_language" id="lan" onChange={handleInputChange}>
            <option value="en">English (US)</option>
            <option value="pt">Portuguese (BR)</option>
            <option value="es">Spanish (CR)</option>
          </select>
        </FormControl_div>
        <FormControl_div>
          <label>Passcode</label>
          <input
            onChange={handleInputChange}
            name="passcode"
            type="text"
          ></input>
        </FormControl_div>
        <button type="submit">Submit</button>
      </Form>
{userInput.file && (
        <audio controls>
          <source src={URL.createObjectURL(userInput.file)} type="audio/wav" />
        </audio>
      )}
    </div>
  );
}
