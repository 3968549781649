//core imports
import React from "react";
import {
  createBrowserRouter as Router,
  RouterProvider,
  Navigate,
} from "react-router-dom";
//custom imports
import Root from "./Root/Root";

import HomePage from "./Home/HomePage";
import AAILanguage from "./Languange/AAILanguage";
import DetectLanguage from "./Languange/DetectLanguage";
import { action as detectAPI } from "./Languange/DetectLanguage";

import AAIVision from "./Vision/AAIVision";
import DocIntel from "./DocIntel/DocIntel";
import { action as docIntelAction } from "./DocIntel/DocIntel";

import AAISpeech from "./AAISpeech/TextToSpeech";
import { action as textToSpeechAction } from "./AAISpeech/AAISpeech";
import { action as speechTranslate } from "./AAISpeech/AAISpeechTranslate";

import SpeechToText from "./SpeechToText/SpeechToText";
import { action as speechToTextAction } from "./SpeechToText/SpeechToText";

//styles
import "./App.css";
import AAISpeechTranslate from "./AAISpeech/AAISpeechTranslate";

const router = Router([
  {
    path: "/",
    element: <Root />,
    id: "root",

    children: [
      { path: "", index: true, element: <HomePage /> },
      {
        path: "detect-language",
        element: <DetectLanguage />,
        action: detectAPI,
      },
      {
        path: "text-to-speech",
        element: <AAISpeech />,
        action: textToSpeechAction,
      },
      {
        path: "speech-to-text",
        element: <SpeechToText />,
        action: speechToTextAction,
      },
      { path: "analyze-image", element: <AAIVision /> },
      {
        path: "translate-speech",
        element: <AAISpeechTranslate />,
        action: speechTranslate,
      },
      {
        path: "/document-intelligence",
        element: <DocIntel />,
        action: docIntelAction,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
