import react from "react";
import {
  Form,
  useSubmit,
  useFormAction,
  useActionData,
  useFetcher,
  redirect,
  useNavigation,
} from "react-router-dom";
import { useState } from "react";
import { styled as muistyled } from "@mui/material/styles";
import Button from "@mui/material/Button";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import styled from "styled-components";

const UploadComponent_div = styled.div`
  margin: auto;
  border: 1px solid #f5f5f2;

  border-radius: 0.5rem;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  width: 100%;

  background-color: #f5f5f5;
`;

const UploadButton_div = styled.div`
  border: 1px solid #fff;
  border-radius: 0.5rem;
  margin: 1rem;
  padding: 1rem;
  display: block;
  width: 50%;
  text-align: left;
  h1 {
    text-align: left;
    margin: 0;
    padding: 0;
    margin-left: 10px;
  }

  p {
    text-align: left;
    margin: 0;
    margin-left: 10px;
  }

  form {
    margin-top: 10px;
    margin-left: 10px;
  }
`;

const Image_div = styled.div`
  border: 1px solid #fff;
  background-color: #373c528c;
  margin: auto;
  margin-top: 10px;
  height: 30vh;
  width: 95%;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;

  img {
    width: 300px;
    height: 300px;
    object-fit: contain;
  }
  p {
    margin: 5px 10px;

    text-align: left;
    font-size: 0.8rem;
    color: #fff;
  }
`;

const Samples_div = styled.div`
  border: 1px solid #fff;
  border-radius: 0.5rem;
  margin: auto;
  height: 30vh;

  display: flex;
  gap: 1rem;
  img {
    width: 100px;
    height: 100px;
  }
`;

const Response_div = styled.div`
  margin: auto;
  margin-top: 2rem;
  padding: 10px;
  border: 1px solid #f5f5f2;
  background-color: #f5f5f2;
  height: fit-content;
  display: block;
  justify-content: space-between;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Responose_data_div = styled.div`
  width: 50%;

  img {
    width: 50%;
    height: auto;
    float: right;
  }

  pre {
    font-size: 0.8rem;
    width: 80%;
  }
`;

const FormControlAction_div = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  width: 100%;
`;

const VisuallyHiddenInput = muistyled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Status_div = styled.div`
  margin: auto;

  height: fit-content;
  display: flex;
  justify-content: center;
`;

const SampleImage_div = styled.div`
  margin: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;

  gap: 1rem;
  img {
    width: 100px;
    height: 100px;
  }
`;

export default function AnalyzeImage() {
  const responseData = useActionData();
  console.log(responseData);
  const submit = useSubmit();
  const status = useNavigation().state;
  console.log(status);
  const [images, setImages] = useState([]);
  const [requestResponse, setRequestResponse] = useState(null);
  const [userInput, setUserInput] = useState({
    file: null,
    passcode: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageLoad = (event) => {
    setUserInput((prevState) => ({
      ...prevState,
      file: event.target.files[0],
    }));
  };
  const handleFormData = () => {
    const formData = new FormData();
    formData.append("file", userInput.file);
    formData.append("passcode", userInput.passcode);
    submit(formData, {
      method: "post",
      action: "./",
      encType: "multipart/form-data",
    });

    setImages([]);
  };

  return (
    <div>
      <UploadComponent_div>
        <UploadButton_div>
          <h1>File upload</h1>
          <p>
            Upload a file to be analyzed by Azure AI Document Intelligence. You
            can download a sample from the image samples on the right.
          </p>

          <Form>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              // startIcon={}
              // startIcon={CloudUploadIcon}
            >
              Select a file
              <VisuallyHiddenInput
                type="file"
                accept="image/*, .pdf .doc"
                name="images"
                onChange={(event) => {
                  setImages(Array.from(event.target.files));
                  handleImageLoad(event);
                }}
                multiple
              />
            </Button>
            {/* <label>Passcode</label>
          <input
            onChange={handleInputChange}
            name="passcode"
            type="text"
          ></input> */}
            {images.length > 0 && (
              <Image_div>
                <p>Preview Image:</p>
                {images.map((img) => (
                  <img
                    key={img.name}
                    src={URL.createObjectURL(img)}
                    alt={img.name}
                  />
                ))}

                {/* <img src={URL.createObjectURL(images[0])} alt={images[0].name} /> */}
              </Image_div>
            )}

            {images.length > 0 && (
              <FormControlAction_div>
                <Button onClick={handleFormData}>Submit</Button>
              </FormControlAction_div>
            )}
          </Form>
        </UploadButton_div>
        
        <Samples_div>
          
          <SampleImage_div>
            <img
              src={
                "https://mstrnaistorageact.blob.core.windows.net/proofpaycontainer/devmodel/57b284c4-1125-454c-839b-ba5d7484ed40.jpeg"
              }
              alt={"sample1"}
            />
            <Button href="https://mstrnaistorageact.blob.core.windows.net/proofpaycontainer/devmodel/57b284c4-1125-454c-839b-ba5d7484ed40.jpeg">
              Download
            </Button>
          </SampleImage_div>
          <SampleImage_div>
            <img
              src={
                "https://mstrnaistorageact.blob.core.windows.net/proofpaycontainer/devmodel/c97b34e5-72f1-4e77-a081-48d9594f5de7.jpeg"
              }
              alt={"sample1"}
            />
            <Button href="https://mstrnaistorageact.blob.core.windows.net/proofpaycontainer/devmodel/c97b34e5-72f1-4e77-a081-48d9594f5de7.jpeg">
              Download
            </Button>
          </SampleImage_div>
        </Samples_div>
      </UploadComponent_div>
      <Response_div>
        {status === "submitting" && (
          <Status_div>
            <h1>{status}</h1>
          </Status_div>
        )}
        {responseData && status !== "submitting" && (
          <div>
            <h2>Response(JSON)</h2>
            <Responose_data_div>
              <pre>{JSON.stringify(responseData, null, 2)}</pre>
            </Responose_data_div>
            <Responose_data_div>
              <img src={responseData.url} alt={responseData.voucher_id} />
            </Responose_data_div>
          </div>
        )}
      </Response_div>
    </div>
  );
}
