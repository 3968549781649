import AnalyzeImage from './aitest'

export default function AAIVision() {
  return (
    <div>
      {" "}
      <div>
        <h1>Vision Services</h1>
      </div>
      <div>
        <AnalyzeImage/>
      </div>
    </div>
  );
}
