import axios from "axios";
import { useState } from "react";
import SpeechTranslateForm from "./SpeechTranslateForm";
import SpeechTranslateResult from "./SpeechTranslateResult";

import { redirect, json } from "react-router-dom";

import {
  LabComponent_div,
  LabComponents_div,
  LabDecription_div,
  LabComponent,
} from "../SharedStyles/LabStyles";
import { Button } from "@mui/material";

const LABCOMPONENTS = [
  {
    title: "FrontEnd",
    description:
      "A Static Web Site (this page) developed with the ReactJS library. The frontend sends a POST request with the audio file, the target language for the translation and a passcode.",
    code: {
      sample: `async function action({ request }) {
  const data = await request.formData();

  try {
    const response = await axios.post(
      [backendserver]/speech-translate,
      data
    );


    if (response.status == 201) {
      return response;
    }
  } catch (error) {
      if (error.response.status == 400) {
      return {
        status: error.response.status,
        error: "Your request is missing data",
      };
    }
    if (error.response.status == 401) {
      return { status: error.response.status, error: "Invalid passcode" };
    }
    return { error: "Something went wrong, please try again!" };
  }
}`,
      languange: "javascript",
    },
  },
  {
    title: "Backend",
    description: "API REST coded with Pyton Flask",
    code: { sample: null, languange: null },
  },
  {
    title: "Azure AI",
    description: "Custom trained document intelligence project",
    code: { sample: null, languange: null },
  },
  {
    title: "Database",
    description: "Not yet developed",
    code: { sample: null, languange: null },
  },
];

export default function AAISpeechTranslate() {
  return (
    <LabDecription_div>
      <h1>Lab: Translate Speech</h1>
      <p>
        {" "}
        This lab is an implemetation of the Speech Transalation service. The
        backend API receives and process an audio file from which the speech is
        recognized and converted To a string. This text is processed and
        translated to the target language and then syntetized to an audio file
      </p>

      <h2>Components</h2>
      <LabComponents_div>
        {LABCOMPONENTS.map((component) => (
          <LabComponent
            key={component.title}
            title={component.title}
            description={component.description}
            code={component.code}
          />
        ))}
      </LabComponents_div>

      <SpeechTranslateResult />
      <SpeechTranslateForm />
    </LabDecription_div>
  );
}

export async function action({ request }) {
  const data = await request.formData();

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}/speech-translate`,
      data
    );
    console.log(response);

    if (response.status == 201) {
      return response;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status == 400) {
      return {
        status: error.response.status,
        error: "Your request is missing data",
      };
    }
    if (error.response.status == 401) {
      return { status: error.response.status, error: "Invalid passcode" };
    }
    return { error: "Unknown Error, please try again!" };
  }
}
