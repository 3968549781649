import styled from "styled-components";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { Form, useActionData, useNavigation } from "react-router-dom";

import {
  LabDecription_div,
  LabComponents_div,
  LabComponent_div,
} from "../SharedStyles/LabStyles";
const Form_Div = styled.div`
  padding-top: 50px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-bottom: 30px;
`;

const ACTION_BUTTONS_DIV = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

const Player_Div = styled.div`
  display: flex;
  background-color: #2c486e;
  width: 70%;
  height: fit-content;
  padding: 10px 0px;
  margin: auto;
  justify-content: center;
  align-items: center;
`;

export default function TextToSpeech() {
  console.log(process.env.REACT_APP_BACKEND_SERVER)
  const [userInput, setUserInput] = useState({
    input_text: "",
    passcode: "",
  });

  const [text, setText] = useState("");
  const [inUse, setInUse] = useState(false);
  const [url, setUrl] = useState("");

  const responseData = useActionData() || null
  console.log(responseData);
  const navigationStatus = useNavigation().status

  useEffect(() => {
    if (responseData !== null) {
      if (responseData.status === 200) {
        setUrl(responseData.data.output_audio_url);
        console.log(url);
      }
    }
  }, [responseData]);

  const handleUserInput = (event) => {
    const { name, value } = event.target;
    setUserInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCleanUrls = () => {
    setUrl("");
    setText("");
    setInUse(false);
  };
  return (
    <LabDecription_div>
      <h1>Lab: Azure AI Text to Speech Service</h1>
      <p>
        {" "}
        This is a simple lab that processes the text from the input, detects the
        language automatically and converts it to speech using the Text to
        speech service
      </p>
      <h2>Goal</h2>
      <p>
        The goal is to process a "Text to Speech" request end-to-end by passing
        the text string from the FrontEnd via a POST request with JSON data.
        Data is processed in BackEnd by using languange detection and the speech
        synthesizer function. Results are passed back to the FrontEnd as JSON
        with the URL to the audio file.
      </p>

      <LabComponents_div>
        <LabComponent_div>
          <h3>User Interface</h3>
          <p>Static Web Site (this page) coded with ReactJS</p>
        </LabComponent_div>
        <LabComponent_div>
          <h3>Backend</h3>
          <p>API REST coded with Pyton Flask</p>
        </LabComponent_div>
        <LabComponent_div>
          <h3>Azure AI </h3>
          <p>Custom trained document intelligence project</p>
        </LabComponent_div>
        <LabComponent_div>
          <h3>Database</h3>
          <p> Not yet developed</p>
        </LabComponent_div>
        <LabComponent_div>
          <h3>Storage</h3>
          <p> Azure Blob Storage</p>
        </LabComponent_div>
      </LabComponents_div>

      <Form method="POST" action="/text-to-speech">
        <Form_Div>
          <Box
            component="div"
            sx={{ "& .MuiTextField-root": { m: 1, width: "40vw" } }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="passcode"
                name="passcode"
                label="Passcode"
                type="password"
                value={userInput.passcode}
                onChange={handleUserInput}
              />
            </div>
            <Box
              component="div"
              sx={{ "& .MuiTextField-root": { m: 1, width: "80vw" } }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="input_text"
                  name="input_text"
                  label="Escribe aca"
                  multiline
                  rows={4}
                  value={userInput.input_text}
                  onChange={handleUserInput}
                />
              </div>
            </Box>

            <ACTION_BUTTONS_DIV>
              <Button type={"submit"} variant="contained" disabled={false}>
                Convert
              </Button>

            </ACTION_BUTTONS_DIV>
          </Box>
        </Form_Div>
      </Form>

      {navigationStatus == "submitting" && <p>Procesando</p>}

      {responseData !== null && (
        <div>
          <h3>Text processed in BackEnd</h3>
          <p>{responseData.data.input_text}</p>
          <h3>Language ISO code</h3>
          <p>{responseData.data.detected_language}</p>
          <h3>Audio</h3>
        </div>
      )}

      <Player_Div>{url && <audio src={url} controls />}</Player_Div>
    </LabDecription_div>
  );
}
