import axios from "axios";
import TextToSpeech from "./TextToSpeech";

export default function AAISpeech() {
  
  return <TextToSpeech />;
}

export async function action({ request }) {
  const data = await request.formData();

  const apiData = {
    input_text: data.get("input_text"),
    passcode: data.get("passcode"),
  };

  console.log(apiData);

  const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/text-to-speech`, apiData);


  return response;
}
