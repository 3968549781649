import React, { useRef, useState } from "react";
import { useSubmit } from "react-router-dom";
import { MediaRecorder, register } from "extendable-media-recorder";
import { connect } from "extendable-media-recorder-wav-encoder";
import wavefile from "wavefile";
import Fab from "@mui/material/Fab";
import MicNoneIcon from "@mui/icons-material/MicNone";
try {
  await register(await connect());
} catch (e) {
  console.log(e);
}

const AudioRecorder = () => {
  const submitFile = useSubmit();
  const formData = new FormData();

  const [recordedUrl, setRecordedUrl] = useState("");
  const [file, setFile] = useState(null);
  const [onHold, setOnHold]= useState(false)
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const startRecording = async () => {
    setOnHold(true)
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream, {
        mimeType: "audio/wav",
      });
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: "audio/wav" });

        const wavefile = new File([recordedBlob], "temporary_recording.wav", {
          type: "audio/wav",
          lastModified: Date.now(),
          size: recordedBlob.size,
        });

        const url = URL.createObjectURL(wavefile);
        setRecordedUrl(url);

        formData.append("file", wavefile);
        formData.append("recognition_language", "en-Us");
        submitFile(formData, {
          method: "post",
          action: "/speech-to-text",
          encType: "multipart/form-data",
        });
        chunks.current = [];
      };
      mediaRecorder.current.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };
  const stopRecording = () => {
    setOnHold(false)
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };
  return (
    <div>
      <Fab color={onHold ? "error": "primary"} aria-label="add">
        <MicNoneIcon onMouseDown={startRecording} onMouseUp={stopRecording} />
      </Fab>
    </div>
  );
};
export default AudioRecorder;
