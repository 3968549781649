import AudioRecorder from "./AudioRecorder";
import { useActionData } from "react-router-dom";
import axios from "axios";
import VoiceNotes from "./VoiceNotes";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styled from "styled-components";
import {
  LabDecription_div,
  Actions_div,
  LabAction_div,
} from "../SharedStyles/LabStyles";

const Results_div = styled.div`
  display: block;
  width: 100%;
  h1 {
    padding:1rem;
    font-size: 14px;
  }
`;

const Notes_div = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: left;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #f5f5ff;
  background-color: #fff;
  border-radius: 0.5rem;
  min-width: 30%;
  max-width: 90%;
  margin: auto;
  margin-top: 1rem;
  p {
    padding-bottom: 1rem;
    font-size: 14px;
  }
  h1 {
    padding-bottom: 1rem;
  }
  h2 {
    padding-bottom: 1rem;
  }
  h3 {
    padding-bottom: 1rem;
    font-weight: bold;
    color: #141414;
  }
`;
var randomColor = ""
const Note_div = styled.div`

  border: 1px solid #adadad73;
  min-width: 25%;
  min-height: 150px;
  width: fit-content;
  padding: 1rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

export default function SpeechToText() {
  const responseData = useActionData();
  const [showCode, setShowCode] = useState(false);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    if (responseData && responseData.text) {
      setNotes((prevNotes) => [...prevNotes, responseData.text]);
      randomColor = Math.floor(Math.random()*16777215).toString(16);
    }
  }, [responseData]);

  const showCodeHandler = () => {
    setShowCode(!showCode);
  };

  return (
    <LabDecription_div>
      <h1>Lab: Speech to Text</h1>
      <h2>Service: Language Understanding</h2>
      <p>
        This lab uses the speech SDK to recognize speech and turn it into text.
      </p>

      <Actions_div>
        <Button variant="outlined" onClick={showCodeHandler}>
          Try it out!
        </Button>
        <Button variant="outlined" onClick={showCodeHandler}>
          View Code
        </Button>
      </Actions_div>
      <LabAction_div>
        <p>Press and hold to record note. Release to stop recording</p>
        <AudioRecorder />
      </LabAction_div>
      <Results_div>
        <h1>Voice notes will appear next.</h1>
        <Notes_div>
          {notes.map((note, index) => (
            <Note_div style={{backgroundColor:'azure'}}>
              <p key={index}>{note}</p>
            </Note_div>
          ))}
        </Notes_div>
      </Results_div>

      {/* <VoiceNotes text={"Some title text"} /> */}
    </LabDecription_div>
  );
}

export async function action({ request }) {
  const data = await request.formData();
  const file = data.get("file");
  console.log(file);
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_SERVER}/speech-to-text`,
    data
  );

  if (response.status !== 201) {
  }
  console.log(response.data.text);
  return response.data;
}
