import { useState } from "react";
import { Form } from "react-router-dom";
import styled from "styled-components";

const Note_div = styled.div`
  width: 30%;
  min-width: fit-content;
  height: 400px;
  min-height: fit-content;
  border: 1px solid red;
  border-radius: 0.5rem;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #f0f6f6;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  &:focus {
    outline: none;
  }

  p {
    color: #000;
    font-size: 0.8rem;
  }
`;

const Title_input = styled.input`
  display: block;
  width: min-content;
  height: 30px;
  border: 1px solid transparent;
  border-bottom: 1px solid #fff;
  border-radius: 0.5rem;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: transparent;
  color: #084b83;
  font-size: 1rem;
  font-weight: 600;
  &:focus {
    outline: none;
  }
`;

const Title2_input = styled.input`
  display: block;
  width: min-content;
  height: 30px;
  border: 1px solid transparent;

  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: transparent;
  color: #084b83;
  font-size: 0.8;
  font-weight: 600;
  &:focus {
    outline: none;
  }
`;

const Paragraph_input = styled.input`
  display: block;
  width: min-content;
  height: 30px;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: transparent;
  color: #000;
  font-size: 0.6rem;

  &:focus {
    outline: none;
  }
`;
const NewNote = ({ textprop }) => {
  const dateTime = new Date().toLocaleString();
  const noteData = {
    title: "Ester Piscore",
    data: [
      "p./ This is the pencil of Ester Piscore",
      "t2./ Luis Jefferson",
      "p./ This is not the pencil of Luis Jefferson",
      "li./ Look at the fit",
      "li./ clapping hand at the publico",
      "li./ went to the bathroom",
    ],
  };
  const [text, setText] = useState("");
  return (
    <Note_div>
      <Form method="post" action="/notes">
        <Title_input type="text" name="title" value={noteData.title} />
        {noteData.data.map((item, index) => {
          if (item.startsWith("p.")) {
            return <Paragraph_input key={index} value={item.split("p./")[1]} />;
          } else if (item.startsWith("t2.")) {
            return <Title2_input key={index} value={item.split("t2./")[1]} />;
          } else if (item.startsWith("li.")) {
            return (
              <li key={index}>
                <input value={item.split("li./")[1]}></input>
              </li>
            );
          } else {
            return null;
          }
        })}

        <button type="submit">+</button>
      </Form>
    </Note_div>
  );
};

export default NewNote;
