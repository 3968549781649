import axios from "axios";
import VoucherUpload from "./VoucherUpload";
import { useState } from "react";

import styled from "styled-components";
import { Button } from "@mui/material";

const LabDecription_div = styled.div`
  border: 1px solid #f5f5ff;
  border-radius: 0.5rem;
  margin: auto;
  margin-top: 20px;
  padding: 1rem;
  width: 90%;
  background-color: #f5f5f5;
  p {
    padding-bottom: 1rem;
  }
`;

const LabComponents_div = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 2rem;
  gap: 1rem;
`;
const LabComponent_div = styled.div`
  padding: 1rem;
  border: 1px solid #f5f5ff;
  border-radius: 0.5rem;
`;

const AppSection_div = styled.div`
  border: 1px solid #f5f5ff;
  padding: 1rem;
  display: block;
  border-radius: 0.5rem;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  background-color: #fff;

  h1 {
    margin: auto;
    text-align: left;
    padding: 1rem;
    border-bottom: 1px solid #f5f5ff;
    width: 100%;
  }
`;

const AppActions_div = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
`;

export default function DocIntel() {
  const [userUpload, setUserUpload] = useState(false);
  function handleUserUpload() {
    setUserUpload(!userUpload);
  }
  return (
    <div>
      <LabDecription_div>
        <h1>Document Intelligence</h1>
        <h2>Azure AI</h2>
        <p>
          This lab uses a custom model to extract data from very specific money
          transfer receipts / vouchers.
        </p>

        <h2>Components</h2>
        <LabComponents_div>
          <LabComponent_div>
            <h3>User Interface</h3>
            <p>Static Web Site (this page) coded with ReactJS</p>
          </LabComponent_div>
          <LabComponent_div>
            <h3>Backend</h3>
            <p>API REST coded with Pyton Flask</p>
          </LabComponent_div>
          <LabComponent_div>
            <h3>Azure AI </h3>
            <p>Custom trained document intelligence project</p>
          </LabComponent_div>
          <LabComponent_div>
            <h3>Database</h3>
            <p> Not yet developed</p>
          </LabComponent_div>
          <LabComponent_div>
            <h3>Storage</h3>
            <p> Azure Blob Storage</p>
          </LabComponent_div>
        </LabComponents_div>
      </LabDecription_div>

      <AppSection_div>
        <h1>Sample Application</h1>
        <p>
          The sample app has two main functions:
          <ul>
            <li>
              The user nterface which allows users to upload a file to be
              analyzed by Azure AI Document Intelligence.{" "}
            </li>
            <li>
              The admin intergace that allows the admin to review a report with
              the data obtained from the user's files.
            </li>
          </ul>
        </p>

        <AppActions_div>
          <Button onClick={handleUserUpload} variant="contained">
            User Upload
          </Button>
          <Button variant="contained">Admin Report</Button>
        </AppActions_div>
        {userUpload && <VoucherUpload />}
      </AppSection_div>
    </div>
  );
}

export async function action({ request }) {
  const data = await request.formData();

  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_SERVER}/documentintel/upload`,
    data
  );

  if (response.status !== 201) {
  }
  return response.data;
}
