import react, { useEffect } from "react";
import { Form, useSubmit, useNavigate, useNavigation } from "react-router-dom";
import { useState } from "react";
import { styled as muistyled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import axios from "axios";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import styled from "styled-components";

const Image_div = styled.div`
  margin: auto;
  border: 1px solid red;
  height: 20vh;
  width: 80%;
  display: flex;

  img {
    width: 200px;
    height: 100%;
  }
`;

const VisuallyHiddenInput = muistyled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AnalyzeImage() {
  const state = useNavigation();

  const [images, setImages] = useState([]);
  const [airesponse, setairesponse] = useState(null);

  if (airesponse) {
    console.log(airesponse);
  }

  useEffect(() => {
    console.log(airesponse);
  }, [airesponse, state]);

  const handleSaveImages = async () => {
    console.log(state);
    const request = new FormData();
    images.forEach((image) => {
      request.append("images", image);
    });

    const response = await axios.post(
      `http://localhost:5000/ai-image-analysis`,
      request
    );

    setairesponse(response.data.urls);
    console.log(response);
    return response;
  };

  return (
    <div>
      <h1>Analyze an image - Axios Ver</h1>;
      {images.length > 0 && (
        <Image_div>
          {images.map((img) => (
            <img src={URL.createObjectURL(img)} alt={img.name} />
          ))}
        </Image_div>
      )}
      <Form method="POST">
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          // startIcon={}
          // startIcon={CloudUploadIcon}
        >
          Select Images
          <VisuallyHiddenInput
            type="file"
            name="images"
            onChange={(event) => {
              setImages(Array.from(event.target.files));
            }}
            multiple
          />
        </Button>

        <Button onClick={handleSaveImages}> Save</Button>
      </Form>
      {airesponse &&
        airesponse.map((url) => {
          return <img key={url} src={url} />;
        })}
    </div>
  );
}

// export async function action({ request }) {
//   const data = await request.formData();

//   const images = data.getAll("images");

//   const form = new FormData();

//   images.forEach((image) => {
//     form.append("images", image);
//   });

//   const response = await axios.post(
//     `http://localhost:5000/ai-image-analysis`,
//     form,
//     {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     }
//   );
//   console.log(response);
//   return response;
// }
