import styled from "styled-components";

import { Link } from "react-router-dom";

const Styled_header = styled.header`
  display: block;
  top: 0;
  margin: auto;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: fit-content;
  min-height: fit-content;
  margin: auto;
  background-color: azure;
`;

const Styled_ul = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: azure;
  color: #000;
  font-size: 1rem;
  font-weight: normal;
  &:focus {
    outline: none;
  }
  li {
    display: inline-block;
    padding: 10px;
    margin: 10px;
    background-color: transparent;
    color: #000;
    font-size: 1rem;
    font-weight: normal;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  a {
    text-decoration: none;
    color: #000;
    font-size: 0.8rem;
    font-weight: normal;
    &:focus {
      outline: none;
    }
  }
`;

const MYLABS = [
  {
    title: "Detect Language",
    description: "Detect Language",
    link: "/detect-language",
  },
  {
    title: "Analyze Image",
    description: "Analyze Image",
    link: "/analyze-image",
  },
  {
    title: "Speech To Text",
    description: "Speech To Text",
    link: "/speech-to-text",
  },
  {
    title: "Text To Speech",
    description: "Text To Speech",
    link: "/text-to-speech",
  },
  {
    title: "Translate Speech",
    description: "Translate Text",
    link: "/translate-speech",
  },
  {
    title: "Translate Text",
    description: "Translate Text",
    link: "/translate-text",
  },
  {
    title: "Document Intelligence Solution",
    description: "Document Intelligence Solution",
    link: "/document-intelligence",
  },
];
export default function Header() {
  return (
    <Styled_header>
      <Styled_ul>
        <li>Home</li>
        <li>
          {" "}
          <Link to={"https://cfm-cr.online"}>About me </Link>
        </li>
        <li>Labs</li>
      </Styled_ul>
      <Styled_ul>
        {MYLABS.map((lab) => (
          <li key={lab.title}>
            <Link to={lab.link}>{lab.title}</Link>
          </li>
        ))}
      </Styled_ul>
    </Styled_header>
  );
}
