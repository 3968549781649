import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Shared/Header";

// import { getTokenDuration } from "../util/authToken";

const Root = () => {
  return (
    <main>
     <Header/>
      <Outlet />
    </main>
  );
};
export default Root;
