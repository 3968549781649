import React from "react";
import { Link } from "react-router-dom";

import {
  LabDecription_div,
  LabComponents_div,
  LabComponent_div,
} from "../SharedStyles/LabStyles";

const HomePage = () => {
  return (
    <LabDecription_div>
      <h1>AI-102 Labs </h1>
      <p>
        This is a collection of labs that use Azure AI services to process
        documents, images, and text.
      </p>

      <LabComponents_div>
        <LabComponent_div>
          <h2>Lab: Azure AI Speech Service</h2>
          <p>
            Translation of speech builds on speech recognition by recognizing
            and transcribing spoken input in a specified language, and returning
            translations of the transcription in one or more other languages.
          </p>
          <Link to="/ai-speech-translate">Translate speech</Link>
        </LabComponent_div>

        <LabComponent_div>
          <h2>Lab: Image Analysis</h2>
          <p>
            This lab uses the Image Analysis service to analyze images and
            extract data.
          </p>
          <Link to="/ai-vision">Image Analysis</Link>
        </LabComponent_div>

        <LabComponent_div>
          <h2>Lab: Text to Speech </h2>
          <p>
            This lab uses the Text Analytics service to analyze text and extract
            data.
          </p>
          <Link to="/text-to-speech">Convert text to speech</Link>
        </LabComponent_div>
        <LabComponent_div>
          <h2>Lab: Document Intelligence</h2>
          <p>
            This lab uses the Document Intelligence service to analyze documents
            and extract data.
          </p>
          <Link to="/doc-intel/voucher-processing">Document Intelligence</Link>
        </LabComponent_div>
        <LabComponent_div>
        <h2>Lab: Basic Language Detection</h2>
          <p>
            This lab uses the Document Intelligence service to analyze documents
            and extract data.
          </p>
          <Link to="/ai-language">Langage Detection</Link>
        </LabComponent_div>
      </LabComponents_div>
    </LabDecription_div>
  );
};

export default HomePage;
