import { useActionData, useNavigation } from "react-router-dom";

export default function SpeechTranslateResult() {
  const responseData = useActionData();
  console.log(responseData);
  const navigationStatus = useNavigation().state;
  console.log(navigationStatus);
  return (
    <div>
      <h1>Result</h1>

      {navigationStatus === "submitting" && <p>Submitting...</p>}
      {navigationStatus === "idle" && (
        <p>The results of the api call will be shown here</p>
      )}
      {responseData && responseData.status == 400 && (
        <p style={{ color: "red" }}>{responseData.error}</p>
      )}
      {responseData && responseData.status == 201 && (
        <div>
          <h2>Original audio text: </h2>
          <p>{responseData.data.text}</p>
          <audio controls>
            <source src={responseData.data.input_audio_url} type="audio/wav" />
          </audio>
          <h2>Translated audio text:</h2>
          <p> {responseData.data.text_translation}</p>
          <audio controls>
            <source src={responseData.data.output_audio_url} type="audio/wav" />
          </audio>
          <h1>Target Languange: {responseData.data.target_language}</h1>
        </div>
      )}
    </div>
  );
}
