import { Form } from "react-router-dom";

import APIs from "./APIs";
//styling import
import styled from "styled-components";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const SD_div = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding: 20px 20px;

  background-color: #2d3250;
  h1 {
    color: #fff;
    margin: auto;
    text-align: left;
  }

  p {
    color: #fff;
  }
`;

const APIs_div = styled.div`
  width: 100%;
  background-color: #f6b17a;
`;

const ROUTES = [
  { id: "detect-lan", name: "Detect Languange", url: "/test" },
  {
    id: "named-entity",
    name: "Entity Recognition",
    url: "/test",
  },
];
export default function AAILanguage() {
  return (
    <>
      <SD_div>
        <h1>Azure AI Language </h1>
        <p>
          Natural Language Processing (NLP) features for understanding and
          analyzing text.{" "}
        </p>
      </SD_div>

      <APIs apiRoutes={ROUTES}></APIs>
    </>
  );
}
